<template>
    <div class="faq-image-container" v-if="hasContentImages">
        <div class="row">
            <div class="col-lg-12 image-container">
                <img :src="`/images/faq/${FAQContent.faqPageRelated.images[sliderIndex]}`">
            </div>
        </div>

        <template v-if="FAQContent.faqPageRelated.images.length > 1">
            <div class="slider-control slider-control-left"
                 :class="{ 'hide-control': sliderIndex === 0 }"
                 @click="changeSliderIndex('left', FAQContent.faqPageRelated.images)">
                <i class="pe-7s-angle-left" />
            </div>
            <div class="slider-control slider-control-right"
                 :class="{ 'hide-control': sliderIndex === FAQContent.faqPageRelated.images.length - 1 }"
                 @click="changeSliderIndex('right', FAQContent.faqPageRelated.images)">
                <i class="pe-7s-angle-right" />
            </div>
        </template>
    </div>
</template>

<style lang="scss" scoped>
    .faq-image-container {
        position: relative;
        border-radius:2px;
        .image-container {
            background: #f5f5f5;
            text-align: center;
            margin: 20px 0;
            border-radius: 5px;
            img {
                max-width: 100%;
                margin: 5px 0;
            }
        }

        .slider-control {
            position: absolute;
            top: 50%;
            cursor: pointer;
            font-size: 32px;
            transition: opacity 0.7s ease;

            &.slider-control-left {
                left: -50px;
            }
            &.slider-control-right {
                right: -50px;
            }

            &.hide-control {
                opacity: 0.2;
                cursor: not-allowed;
            }
        }
    }

    @media screen and (max-width: 992px) {
        .faq-image-container {
            .slider-control {
                height: 39px;
                border-radius: 4px;
                background: #000000;
                color: #ffffff;
                &.slider-control-left {
                    left: 0;
                }
                &.slider-control-right {
                    right: 0;
                }
            }
        }
    }
</style>

<script>
    export default {
        props: {
            FAQContent: Object
        },

        data() {
            return {
                sliderIndex: 0
            }
        },

        computed: {
            hasContentImages() {
                if (!this.FAQContent) {
                    return
                }

                return this.FAQContent.faqPageRelated.images.length > 0
            },


        },

        methods: {
            changeSliderIndex(direction, imageArray) {
                if (direction === 'left' && this.sliderIndex >= 1) {
                    this.sliderIndex--
                    return
                }

                if (direction === 'right' && this.sliderIndex < imageArray.length - 1) {
                    this.sliderIndex++
                }
            }
        }
    }
</script>
