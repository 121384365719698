<template>
    <div class="page-faq">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-4">
                    <card>
                        <div class="faq-list-search">
                            <i class="pe-7s-search"/>
                            <input type="text" v-model="query" placeholder="Søg her ...">
                            <i class="pe-7s-close" :class="{ active: query }" @click="resetQuery"/>
                        </div>

                        <div>
                            <template v-for="group in groupedRelations">
                                <div class="group-list" @click="toggleGroupView(group)">
                                    <h4 class="relation-name">{{$t(`faq.page.${group[0].relation}`)}}</h4>
                                </div>

                                <div class="group-list">
                                    <ul class="group-list-items" v-if="openGroups.includes(group)">
                                        <li class="group-content"
                                            :class="{ 'selected-group': selectedContentId === content.id }"
                                            v-for="content in group"
                                            @click="selectContent(content.id)"
                                            v-html="highlight(content.headline)"
                                        />
                                    </ul>
                                </div>
                            </template>
                        </div>
                    </card>
                </div>

                <div class="col-lg-8">
                    <card>
                        <div class="content">
                            <template v-if="selectedContent">
                                <h5 class="page-headline">{{selectedContent.headline}}</h5>
                                <div class="clearfix"></div>

                                <hr>

                                <!-- IF Content Contains Guide -->
                                <template v-if="selectedContent.guideContent">
                                    <div v-for="content in selectedContent.guideContent" style="margin-bottom: 30px;">
                                        <strong>{{content.headline}}</strong>
                                        <p>{{content.content}}</p>
                                        <ol>
                                            <li v-for="listItem in content.ol">{{listItem.li}}</li>
                                        </ol>
                                        <a :href="content.aURL" target="_blank" rel="noopener noreferrer">{{content.aText}}</a>
                                    </div>

                                    <h5>Ofte stillede spørgsmål</h5>

                                    <hr>

                                    <div v-for="guideFAQContent in selectedContent.guideFAQ" style="margin-bottom: 20px;">
                                        <strong v-if="guideFAQContent.headline">{{guideFAQContent.headline}}</strong>
                                        <p v-if="guideFAQContent.content">{{guideFAQContent.content}}</p>
                                        <ol v-if="guideFAQContent.ol">
                                            <li v-for="listItem in guideFAQContent.ol">{{listItem.li}}</li>
                                        </ol>
                                        <p v-if="guideFAQContent.subContent">{{guideFAQContent.subContent}}</p>
                                    </div>
                                </template>

                                <template v-else>
                                    <p class="written-content" v-html="selectedContent.content"/>

                                    <faq-image-container :FAQContent="selectedContent"/>

                                    <div class="row">
                                        <div class="col-lg-6" v-for="content in columnContent">
                                            <h5>{{$t(`faq.${content.slug}`)}}</h5>
                                            <hr>
                                            <p class="text-muted" v-if="selectedContent.faqPageRelated[`${content.slug}`]">
                                                {{content.slug === 'dataColumn' ? showDataColumn : showRelatedColumn}}
                                            </p>
                                            <p class="text-muted" v-else>{{$t(`faq.${content.noResult}`)}}</p>
                                        </div>
                                    </div>
                                </template>
                            </template>

                            <span class="no-search-result" v-else>{{$t('faq.noSearchResult')}}</span>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_mixins.scss';

    ol li {
        margin-bottom: 10px;
    }

    .faq-list-search {
        position: absolute;
        top: -15px;
        left: -15px;
        width: calc(100% + 30px);
        padding: 20px 30px 15px 30px;
        border-radius: 3px 3px 0 0;
        border-bottom: 1px solid #eaeaea;

        i.pe-7s-search {
            font-size: 16px;
        }

        i.pe-7s-close {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            font-size: 24px;
            transition: opacity 0.5s ease;
            cursor: pointer;
            opacity: 0;

            &.active {
                opacity: 1;
            }
        }

        input[type=text] {
            padding-left: 10px;
            font-size: 16px;
            border: none;
        }
    }

    .content {
        max-width: 600px;
        margin: 0 auto;

        hr {
            margin: 20px 0 !important;
        }

        h5.page-headline {
            margin: 0;
            float: left;
            max-width: 400px;
        }

        p.date {
            float: right;
            max-width: 200px;
        }

        p.written-content {
            font-size: 15px;
            line-height: 23px;
        }
    }

    .no-search-result {
        background: #9b5353;
        font-style: italic;
        display: inline-block;
        text-align: center;
        width: 100%;
        padding: 10px 20px;
        color: #fff;
        border-radius: 5px;
    }

    .group-list {
        margin: 0 -31px;

        h4 {
            border: 1px solid #eaeaea;
            padding: 10px 60px;
            margin: 0;
            font-size: 15px;
            font-weight: 500;
            background: #f9f9f9;
        }

        &:first-child {
            margin-top: 29px;
        }

        .group-list-items {
            padding: 0;
            margin: 0;

            .group-content {
                padding: 10px 60px;
                list-style-type: none;
                cursor: pointer;
                @include ellipsis;

                &:not(:last-child) {
                    border-bottom: 1px solid #f5f5f5;
                }

                &:not(&.selected-group):hover {
                    background: #f5f5f5;
                    border-left: 1px solid #eaeaea;
                    border-right: 1px solid #eaeaea;
                    padding: 10px 59px;
                }
            }
        }
    }

    @media screen and (max-width: 992px) {
        div.content h5:not(.page-headline) {
            margin-top: 30px;
        }
    }
</style>

<script>
  import Card from '@/app/shared/components/Card'
  import faqImageContainer from '@/app/faq/components/FAQImageContainer'

  import { FAQContent } from '@/app/faq/components/FAQContent'

  import { cloneDeep } from 'lodash'

  import Fuse from 'fuse.js'

  export default {
    data () {
      return {
        faqContent: [],
        selectedContentId: 0,
        query: '',
        timeout: null,
        openGroups: [],
        openGroupsSnapshot: [],
        columnContent: [{
          slug: 'dataColumn',
          noResult: 'dataColumnNoResult'
        }, {
          slug: 'relatedColumn',
          noResult: 'relatedColumnNoResult'
        }],
        sliderIndex: 0,
        searchOptions: {
          shouldSort: true,
          threshold: 0.4,
          location: 0,
          distance: 100,
          maxPatternLength: 16,
          minMatchCharLength: 1,
          keys: [
            'headline',
            'tags'
          ]
        }
      }
    },

    watch: {
      query () {
        if (this.timeout) {
          clearTimeout(this.timeout)
        }

        this.timeout = setTimeout(() => {
          this.searchFAQ()
        }, 500)
      }
    },

    computed: {
      selectedContent () {
        if (!this.faqContent.length) {
          return
        }

        return this.faqContent.find(content => content.id === this.selectedContentId)
      },

      showDataColumn () {
        return this.selectedContent.faqPageRelated.dataColumn
      },

      showRelatedColumn () {
        return this.selectedContent.faqPageRelated.relatedColumn
      },

      content () {
        return cloneDeep(this.prepareContent(this.faqContent))
      },

      groupedRelations () {
        let relationGroups = {}
        const clonedFAQContent = this.content

        clonedFAQContent.forEach(content => {
          if (!relationGroups[content.relation]) {
            relationGroups[content.relation] = []
          }

          relationGroups[content.relation].push(content)
        })

        return relationGroups
      }
    },

    mounted () {
      this.faqContent = FAQContent

      this.selectedContentId = parseFloat(this.$route.query.id) || 1

      Object.values(this.groupedRelations).forEach(group => {
        group.forEach(content => {
          if (content.id === this.selectedContentId) {
            this.openGroups.push(group)
          }
        })
      })
    },

    methods: {
      highlight (value) {
        const query = this.query

        value = value.replace(new RegExp(`(${query})`, 'gi'), '<strong>$1</strong>')

        const words = query.split(' ')

        for (let i = 0; i < words.length; i++) {
          value = value.replace(new RegExp(`(${words[i]})` + '(?![^<]*>|[^<>]*</)', 'gi'), '<strong>$1</strong>')
        }

        return value
      },

      prepareContent (content) {
        if (content instanceof Function) {
          return content()
        }

        if (content instanceof Object) {
          for (let key in content) {
            content[key] = this.prepareContent(content[key])
          }
        }

        return content
      },

      resetQuery () {
        this.query = ''
      },

      selectContent (id) {
        this.selectedContentId = id
      },

      searchFAQ () {
        if (!this.query.length) {
          this.faqContent = FAQContent
          return
        }

        const fuse = new Fuse(Object.values(this.content), this.searchOptions)
        this.faqContent = fuse.search(this.query)

        this.openGroups = Object.values(this.groupedRelations)
      },

      toggleGroupView (group) {
        this.openGroups.includes(group)
          ? this.openGroups = this.openGroups.filter(openGroup => openGroup !== group)
          : this.openGroups.push(group)
      }
    },

    components: {
      Card,
      faqImageContainer
    }
  }
</script>
